import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const Dashboard = () => {
  return (
    <Grid container>
      <Typography variant='h2'>Admin Dashboard</Typography>
    </Grid>
  )
}

export default Dashboard
